import React from 'react';
import ReactDOM from 'react-dom/client'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<div id="container">
              <img src="logonew.png"/>
              <table style={{marginTop:"40px"}} height="300px">
              <tr>
              <td class="container" style={{position:"Relative",zIndex:"1"}}>
              <p style={{color:'#a8a8a8',fontSize:"1.8em"}}>Random 1-to-1 Voice Call</p>
              <p style={{color:'#b58797',fontSize:"1.3em"}}>You will be connected with 1 random person at a time.</p>
              <br/>
              <button>Start</button>
              </td>
              <td class="container">
              <p style={{color:'#a8a8a8',fontSize:"1.5em"}}>Join the Anonymous Global Chat</p>
              <form method="POST" action="/setUsername"><input type="text" placeholder="Enter Your Name" id="name" name="name"/> <button type="submit">Go</button></form>
              </td>
              </tr>
              </table>
            </div>);
